<template>
    <div>
        <s-toolbar dense dark color="#7b7b7b" close save @save="save()" @close="$emit('close')"> </s-toolbar>
        <v-col>
            <v-card class="pt-0 elevation-0 pb-0" elevation="0">
                <v-row>
                    <v-col cols="6" lg="4" md="4" sm="6"  class="s-col-form">
                        <s-date v-model="itemHead.FfsDateSampling" label="Fecha"></s-date>
                    </v-col>
                    <v-col cols="6" lg="4" md="4" sm="6" class="s-col-form">
                        <label slot="before">Hora de muestreo</label>
                        <div class="relojito">
                        <datetime title="Hora  :  Minutos"  valueZone="America/Lima" type="time" v-model="itemHead.FfsHour">
                        </datetime>
                        </div>
                    </v-col>
                    <v-col cols="6" lg="4" md="4"  class="s-col-form">
                        <s-text
                        v-model="itemHead.FfSsampledUnits"
                        ref="FocuesFfSsampledUnits"
                        label="Unidades Muestreadas"
                        readonly
                        ></s-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" lg="4" md="4" sm="6"   class="s-col-form">
                        <s-select-definition
                        label="Tipo de Cultivo"
                        v-model="itemHead.TypeCultive"
                        :def="1173"
                        ></s-select-definition>
                    </v-col>
                
                    <v-col cols="6" lg="4" md="4" sm="6"   class="s-col-form">
                        <s-select-definition
                        label="Tipo Corte"
                        v-model="itemHead.TypeCultive"
                        :def="1173"
                        ></s-select-definition>
                    </v-col>
            
                    <v-col cols="6" lg="4" md="4" sm="6"   class="s-col-form">
                        <s-select-definition
                        autocomplete
                        label="Turno"
                        v-model ="itemHead.TypeTurn"
                        :dgr="54"
                        :def="1062"
                        ></s-select-definition>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" lg="4" md="4"  class="s-col-form">
                        <s-select
                        ref="CliMadSelect"
                        label="Clientes de Maduración"
                        :items="listCustomer"
                        v-model="itemHead.CumId"
                        item-value="CumId"
                        item-text="CumNombre"              
                        clearable
                        ></s-select>
                    </v-col>
                <!-- v-model="objCustMaturation" -->
                    <v-col cols="6" lg="4" md="4"  class="s-col-form">
                        <s-select-definition
                        autocomplete
                        label="Turno"
                        v-model ="itemHead.TypeTurn"
                        :dgr="54"
                        :def="1062"
                        ></s-select-definition>
                    </v-col>
                
                    <v-col cols="12" lg="12" md="12" sm="12" class="s-col-form">
                        <s-textarea
                        v-model="itemHead.FfsObservations"
                        label="Observaciones"
                        outlined
                        :length="10"
                        rows="1 "
                        row-height="15"
                        ></s-textarea>
                    </v-col>
                </v-row>
                <!-- fin de datos de la cabecera -->
                <v-row>
                <v-col class="pt-0 pb-3"><v-divider></v-divider></v-col>
                </v-row> 
            </v-card>
        </v-col>
    </div>
</template>

<script>
  


  
  
  export default {
    components: {  },
    props: {
      
    },
  
    data: () => ({
      
      itemHead: {
      
      },
  
     
    }),
  
    methods: {
      
     
      },
  
      save() {
        /* VALIDACIONES */
        
       
      },
  
      
   
     
  
    created() {
  
      //  parametro para punto de muestreo por defecto
  
    
    },
  
    mounted(){
     
  
      this.itemHead.FfsHour = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));
     //this.FfsHour = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));
    }
  };
  </script>
  <style>
  .relojito {
      width:auto;
      height: auto;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(160, 159, 159);
      border-radius: 4px;
      padding: 3px;
    }
    </style>