<template>
    <div>
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>
                    <v-row style="margin-top: 10px">
                        <v-col cols="12" style="display: inline-flex;">
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                            >
                                {{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </v-toolbar>
        </v-card>
        <v-card dense>
            <v-row justify="center" style="margin:auto">
                <v-col
                    align="center"
                    cols="12"
                    lg="12"
                    md="12"
                >   
                    <h1><b>{{objTunnelCamera.CdtDescripcion}}</b></h1>
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                        >

                    </s-scanner-qr>
                </v-col>
            </v-row>
            <v-row style="margin:auto" justify="center">
                <!-- <v-col cols="12">
                    <s-select-definition 
                        :def=1450 
                        label="Almacenes de congelado" 
                        v-model="CdtWarehouses"
                        @input="changeChamber()"
                        clearable
                    >
                    </s-select-definition>  
                </v-col> -->
                <v-col cols="12">
                    <s-select 
                        :items="itemsCamerasTunnels"
                        item-value="CdtID"
						item-text="CdtDescripcion"
                        label="Seleccionar Tunel de Camara"                         
                        return-object
                        v-model="objTunnelCamera"
                        :slotTmp="true" 
                        clearable        
                        >
                    </s-select>
                </v-col>
                <v-col cols="12"> 
                    <v-radio-group
                        v-model="entryTunnel.Side"
                        inline
                        row
                        hide-details>
                        <template v-slot:label>
                            <div>Lados <strong></strong></div>
                        </template>
                        <v-radio v-for="side in this.objTunnelCamera.CdtNumberSides" :label="side.toString()" :value="side"></v-radio> 
                    </v-radio-group> 
                 
                    <v-radio-group
                        v-model="entryTunnel.CdlID"
                        inline
                        row
                        hide-details>
                        <template v-slot:label>
                        <div>Niveles <strong></strong></div>
                        </template>
                        <v-radio v-for="level in lvels" :label="level.Dscription" :value="level.CdlID"></v-radio> 
                    </v-radio-group>    
                 
                    <v-radio-group
                        v-model="entryTunnel.Column"
                        inline
                        row
                        hide-details>
                        <template v-slot:label>
                            <div>Columna <strong></strong></div>
                        </template>
                        <v-radio v-for="column in this.objTunnelCamera.CdtNumberColumn" :label="column.toString()" :value="column"></v-radio>
                        
                    </v-radio-group> 
                
                    <v-radio-group
                        v-model="entryTunnel.Lane"
                        inline
                        row
                        hide-details>
                        <template v-slot:label>
                            <div>Carril <strong></strong></div>
                        </template>
                        <v-radio v-for="lane in this.objTunnelCamera.CdtLane" :label="lane.toString()" :value="lane"></v-radio> 
                    </v-radio-group> 
                </v-col>
            </v-row> 
            <v-row>
                <v-col lg="12" class="pb-1">
                    <!-- <s-crud                      
                        :filter="filter"
						:config="config"
						
						remove
						@save="save($event)"
						searchInput
						
						title="Listado de Pallets"
						no-full
						height="auto"
						dense
						ref="gridPackingAssingBingToLine">
                        <template v-slot:filter>
                            <v-container>
                                <v-row justify="center" class="s-col-form">
                                    <v-col>
                                        <s-date 
                                            label="Fecha"
                                            v-model="filter.DateBegin"></s-date>
                                    </v-col>
                                    <v-col>
                                        <s-date 
                                            label="Fecha Fin"
                                            v-model="filter.DateEnd"></s-date>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:SecStatus="{ row }">
                            <v-icon :color="row.SecStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
                        </template>
                    </s-crud> -->
                </v-col>
            </v-row>          
        </v-card>
    
    <v-dialog
        persistent
        v-model="openModalIncomeTunnel"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-if="openModalIncomeTunnel"
    >

    <v-card class="elevation-0">
				<s-camera-pallet
                @close="closeModalPallet()"
				></s-camera-pallet>
			</v-card>
    </v-dialog>
    </div>
    
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';
    import sCameraPallet from './sCameraPallet.vue';


    import _sFrzCameraIncomePallet from "@/services/FrozenProduction/FrzCameraIncomePallet.js";
    import _sFrzChamberDispatchTunnels from "@/services/FrozenProduction/FrzChamberDispatchTunnels.js";
    

    export default{
        components: { sScannerQr,sCameraPallet },
        data:() => ({
            openModalIncomeTunnel :false,
            clearInput:false,
            configScann: {}, 
            itemsCamerasTunnels: [],
            objTunnelCamera: {},
            filter: {
                DateBegin: null,
                DateEnd: null,                    
            },
            config:{
                // service: _sAssingBingToLine,
                model:{
                    AbpID: "ID",
                    DateBegin: "date",
                    DateEnd: "date",
                    SecStatus: "SecStatus"
                },                
                // headers: [
                //     { text: "ID", value: "AbpID", width: 20 },					
                //     { text: "Linea en proceso", value: "PloDescription", width: 20 },
                //     { text: "N° Bings", value: "AbbID", width: 20 },
                //     { text: "Cliente", value: "CumNombre", width: 20 },
                //     { text: "Cantidad de bolsas", value: "NumberOfBags", width: 20, align: "center"},
                //     { text: "Peso Neto", value: "WeightNet", width: 20, align: "center"},
                //     { text: "N° Pallet", value: "SecStatus", width: 20, align: "center"},
                // ],
            },
            itemCamera:{},
            lvels: [],
            entryTunnel: {},
            CdtWarehouses: 0
        }),
        watch:{
            objTunnelCamera(){
                console.log('this.objTunnelCamera', this.objTunnelCamera) 
                 _sFrzChamberDispatchTunnels.detail(this.objTunnelCamera, this.$fun.getUserID())
                    .then((r) => {
                        console.log('items dataa', r.data)
                        this.lvels = r.data.Levels;
                    })
            }
        },
        
        methods:{
            initialize()
            // changeChamber()
            {
                console.log('lista de camaras')
                _sFrzCameraIncomePallet.getTunnelChamber({},this.$fun.getUserID())
                .then(r =>{
                    if(r.status == 200)
                    {
                        this.itemsCamerasTunnels = r.data;
                    }
                });
            },
            changeTunnel(tunnel){
               
            },
            onValue(val)
            {
                // let item = {};
                // console.log('Dato Qr',val);
                // if (val == "" || isNaN(val)) {
				// 	this.$fun.alert("El QR Leido no contiene datos", "warning");
				// } else {
				// 	item.PapID = val;
                //     console.log('PapID  ->',val);

                //     if (!val) {
                //         return;
                //     }

                //     _sFrzCameraIncomePallet.IdQr(val,this.$fun.getUserID()).then((r) => {
                //         if (r.status == 200) {

                //             // this.PapID = r.data.PapID;
                //             this.openModalIncomeTunnel = true;
                //             this.itemCamera = r.data;
                //             console.log('QR Scaneado', this.itemCamera);
                            
                //         }
                //     });
                // }

                let item = {};
                console.log(this.objTunnelCamera);
                item.CdtID = this.objTunnelCamera.CdtID;
                console.log(item);
                if(item.CdtID == undefined)
                {
                    this.$fun.alert("Por Favor Seleccione Camara ", "warning");
					return;
                }
                // item.PapID = val;
                item.PalletGroupID = val;
                item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();

                item.Lane = this.entryTunnel.Lane;
                item.NumberColumn = this.entryTunnel.Column;
                item.NumberSide = this.entryTunnel.Side;
                item.CdlID = this.entryTunnel.CdlID;

                console.log("item", item);

              
                _sFrzCameraIncomePallet.save(item, this.$fun.getUserID())
                .then( r => {
                    if(r.status == 200)
                    {
                        // this.$refs.gridPackingAssingBingToLine.refresh();
                        this.$fun.alert("Pallet ingresado correctamente", "success");
                        // this.initialize();
                    }
                });
            },
            closeModalPallet(){
                this.openModalIncomeTunnel = false;
            },
        },
        created()
        {
            this.initialize();
        }
    }
</script>